import { Collapse } from '@mui/material';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { TransitionGroup } from 'react-transition-group';
import { useAlert } from '../hooks/alert';
import useDeviceDetect from '../hooks/mobile';
import { useModal } from '../hooks/modal';
import { useGlobalState } from '../hooks/states';
import { PAGE_LIMIT, usePartners } from '../queries/partners';
import { AdSlot } from '../types/AdManagerTypes';
import { LoLPartner, QueueType } from '../types/lol/partner.type';
import { Nullable } from '../types/UtilTypes';
import { getRegion } from '../utils/application';
import AdBox from './AdBox';
import AdSense from './AdSense';
import Alert from './Alert';
import Filter from './Filter';
import PartnerDeleteModal from './PartnerDeleteModal';
import PartnerDetailModal from './PartnerDetailModal';
import PartnerEditModal from './PartnerEditModal';
import PartnerListHeader from './PartnerListHeader';
import PartnerListItem from './PartnerListItem';
import PartnerReportModal from './PartnerReportModal';
import { AlertPortal } from './Portal';
import Spinner from './Spinner';
import ViewMoreButton from './ViewMoreButton';
import QuickPlayPartnerListItem from './PartnerList/Item/QuickPlayPartnerListItem';
import QuickPlayPartnerListHeader from './PartnerList/Header/QuickPlayPartnerListHeader';
import QuickPlayPartnerDetailModal from './modal/partner/QuickPlayPartnerDetailModal';
import { useAnimatedPartners } from '../hooks/subscription';

interface Props {
  className?: string;
}

const PartnerList: React.FC<Props> = ({ className }) => {
  const { isMobile } = useDeviceDetect();
  const [{ position, tier, queueType, isAuth }] = useGlobalState();
  const { partners, loading, hasMore, fetchMore } = usePartners({
    region: getRegion(),
    position,
    tier,
    queueType,
    isAuth,
  });

  const {
    visible: deleteVisible,
    open: openDeleteModal,
    close: closeDeleteModal,
  } = useModal();

  const {
    visible: editVisible,
    open: openEditModal,
    close: closeEditModal,
  } = useModal();

  const {
    visible: detailVisible,
    open: openDetailModal,
    close: closeDetailModal,
  } = useModal();

  const {
    visible: reportVisible,
    open: openReportModal,
    close: closeReportModal,
  } = useModal();

  const { visible, message, errorCode, showAlert, hideAlert } = useAlert();
  const [selectedPartner, setSelectedPartner] =
    useState<Nullable<LoLPartner>>(null);
  const { isAnimated, setAnimatedPartner, animatedPartners } =
    useAnimatedPartners();

  useEffect(() => {
    console.log(animatedPartners);
  }, [animatedPartners]);

  return (
    <section className={classNames(className)}>
      <AdBox className="mb-6" />

      <Filter showAlert={showAlert} />

      {!isEmptyPartnerList() && (
        <div className="my-2 sm:overflow-x-auto sm:overflow-y-hidden sm:pb-28 sm:-mb-28">
          <div className="w-full sm:min-w-container">
            {queueType !== QueueType.NORMAL ? (
              <PartnerListHeader />
            ) : (
              <QuickPlayPartnerListHeader />
            )}
            <TransitionGroup component="ul">
              {partners.map((partner: LoLPartner, index: number) => (
                <Collapse
                  component="li"
                  key={`partner-list-${partner.id}`}
                  className="cursor-pointer bg-gray-800 hover:bg-gray-850 border-b border-solid border-gray-900 last:rounded-b"
                >
                  {index >= PAGE_LIMIT && index % PAGE_LIMIT === 0 && (
                    <div className="flex lg:justify-center cursor-pointer bg-gray-800 hover:bg-gray-850 border-b border-solid border-gray-900">
                      <AdSense
                        key={`ad-sense-middle-${index}`}
                        slot={
                          isMobile ? AdSlot.VIEW_MORE_MOBILE : AdSlot.VIEW_MORE
                        }
                      />
                    </div>
                  )}
                  {queueType !== QueueType.NORMAL ? (
                    <PartnerListItem
                      partner={partner}
                      onDelete={handleModal(partner, openDeleteModal)}
                      onEdit={handleModal(partner, openEditModal)}
                      onReport={handleModal(partner, openReportModal)}
                      onOpenDetail={handleModal(partner, openDetailModal)}
                      animated={isAnimated(partner.id)}
                      setAnimated={(animated) =>
                        setAnimatedPartner(animated, partner.id)
                      }
                    />
                  ) : (
                    <QuickPlayPartnerListItem
                      className={classNames({
                        'list-item__premium': partner?.isSubscription,
                      })}
                      partner={partner}
                      onDelete={handleModal(partner, openDeleteModal)}
                      onEdit={handleModal(partner, openEditModal)}
                      onReport={handleModal(partner, openReportModal)}
                      onOpenDetail={handleModal(partner, openDetailModal)}
                      animated={isAnimated(partner.id)}
                      setAnimated={(animated) =>
                        setAnimatedPartner(animated, partner.id)
                      }
                    />
                  )}
                </Collapse>
              ))}
            </TransitionGroup>
          </div>
        </div>
      )}

      {isEmptyPartnerList() && (
        <section className="empty">
          {!loading ? (
            <FormattedMessage id="No players matches the criteria." />
          ) : (
            <div className="text-center">
              <Spinner className="inline-block" />
            </div>
          )}
        </section>
      )}

      {hasMore && (
        <ViewMoreButton
          className="my-4"
          disabled={loading}
          onFetchMore={handleViewMore}
        />
      )}

      <AdBox className="mt-10" />

      {selectedPartner && (
        <PartnerDeleteModal
          visible={deleteVisible}
          partner={selectedPartner}
          showAlert={showAlert}
          onClose={closeDeleteModal}
        />
      )}

      {selectedPartner && (
        <PartnerEditModal
          visible={editVisible}
          partner={selectedPartner}
          showAlert={showAlert}
          onClose={closeEditModal}
        />
      )}

      {selectedPartner &&
        (queueType !== QueueType.NORMAL ? (
          <PartnerDetailModal
            visible={detailVisible}
            partner={selectedPartner}
            onClose={closeDetailModal}
          />
        ) : (
          <QuickPlayPartnerDetailModal
            visible={detailVisible}
            partner={selectedPartner}
            onClose={closeDetailModal}
          />
        ))}

      {selectedPartner && (
        <PartnerReportModal
          visible={reportVisible}
          partner={selectedPartner}
          onClose={closeReportModal}
          showAlert={showAlert}
        />
      )}

      {visible && (
        <AlertPortal>
          <Alert
            message={message}
            errorCode={errorCode}
            hide={hideAlert}
            className="bottom-14"
          />
        </AlertPortal>
      )}
    </section>
  );

  function isEmptyPartnerList(): boolean {
    return partners.length === 0 || loading;
  }

  function handleModal(partner: LoLPartner, openModal: () => void): () => void {
    return () => {
      setSelectedPartner(partner);
      openModal();
    };
  }

  async function handleViewMore(): Promise<void> {
    try {
      await fetchMore();
    } catch (e) {
      console.error(`Error occured: ${e}`);
    }
  }
};

export default PartnerList;
