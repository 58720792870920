import { useState, useCallback } from 'react';

export const useAnimatedPartners = () => {
  const [animatedPartners, setAnimatedPartners] = useState<string[]>([]);

  const isAnimated = useCallback(
    (id: string) => animatedPartners.includes(id),
    [animatedPartners]
  );

  const setAnimatedPartner = (animated: boolean, id: string) => {
    if (animated) {
      if (isAnimated(id)) return;

      setAnimatedPartners((prev) => [...prev, id]);
    } else {
      setAnimatedPartners(animatedPartners.filter((p) => p !== id));
    }
  };

  return { isAnimated, setAnimatedPartner, animatedPartners };
};
