import { NextPage } from 'next';
import PartnerList from '../../components/PartnerList';
import { NexusStateProvider } from '../../providers/NexusStateProvider';

const Home: NextPage = () => {
  return (
    <NexusStateProvider>
      <PartnerList />
    </NexusStateProvider>
  );
};

export default Home;
