import classNames from 'classnames';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRouter } from 'next/router';
import { LoLPartner, Position } from '../../../types/lol/partner.type';
import { Member } from '../../../types/MemberTypes';
import { Locale } from '../../../types/LocaleTypes';
import { usePartnerOptions } from '../../../hooks/partners';
import { useThemeColors } from '../../../lib/theme';
import { useMyClashTeams } from '../../../queries/clash';
import { useInviteToClashTeam } from '../../../mutations/clash';
import { useAlert } from '../../../hooks/alert';
import { PartnerOption } from '../../../types/PartnerTypes';
import { ErrorCode } from '../../../types/ErrorTypes';
import Alert, { ErrorMessageMap } from '../../Alert';
import Modal from '../Modal';
import SummonerTier from '../../SummonerTier';
import RequiredPositionSelect from '../../RequiredPositionSelect';
import ChampionIcon from '../../ChampionIcon';
import { championDataList } from '../../../data/championData';
import PositionIcon from '../../PositionIcon';
import { AlertPortal } from '../../Portal';
import FullSummonerProfile from '../../SummonerProfile/FullSummonerProfile';

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  visible: boolean;
  partner: LoLPartner;
  member?: Member;
  onClose: () => void;
};

const QuickPlayPartnerDetailModal: React.FC<Props> = ({
  className,
  visible,
  partner,
  member,
  onClose: handleClose,
}) => {
  const { locale = Locale.KO, pathname } = useRouter();
  const {
    summoner,
    position,
    note,
    requiredPosition,
    champion,
    champion2,
    position2,
  } = partner;
  const queueType = partner.queueType?.toUpperCase();
  const { hasOption } = usePartnerOptions(partner);
  const { getWinRateClassName } = useThemeColors();
  const { isOwned: isOwnedClashTeam } = useMyClashTeams();

  const {
    invite,
    loading: pendingForInvitation,
    data: invitationResult,
    error: invitationError,
  } = useInviteToClashTeam();

  const {
    visible: alertVisible,
    message,
    errorCode,
    showAlert,
    hideAlert,
  } = useAlert();

  const hasChampions = React.useMemo(
    () => !!summoner.champions && summoner.champions.length > 0,
    [summoner]
  );

  const certified = React.useMemo(
    () => hasOption(PartnerOption.CERTIFIED),
    [hasOption]
  );

  const isInviteAvailable = React.useMemo(
    () => !member && !partner.isAuthor && certified && isOwnedClashTeam,
    [member, partner.isAuthor, certified, isOwnedClashTeam]
  );

  const inviteButtonDisabled = React.useMemo(
    () =>
      pendingForInvitation ||
      !!invitationResult?.inviteClashTeam ||
      !!invitationError?.message,
    [pendingForInvitation, invitationResult, invitationError]
  );

  const handleInvite = React.useCallback(async () => {
    try {
      const response = await invite({
        variables: { partnerId: Number(partner.id) },
      });

      if (!response.data || !response.data.inviteClashTeam) return;

      if (response.data.inviteClashTeam) {
        showAlert({
          message: {
            key: '{name} summoner was invited to clash team.',
            value: { name: partner.summoner.name },
          },
          errorCode: null,
        });
      }
    } catch (err) {
      if (err instanceof Error) {
        const errorCode = err.message as ErrorCode;
        showAlert({ message: { key: ErrorMessageMap[errorCode] }, errorCode });
      }
    }
  }, [partner]);

  const options = useMemo(() => {
    return {
      premium: partner?.isSubscription,
      certified,
      mic: hasOption(PartnerOption.MIC),
    };
  }, [hasOption]);

  return (
    <Modal
      className={className}
      contentClassName="lg:w-max lg:min-w-[400px] sm:w-full"
      visible={visible}
      onClose={handleClose}
    >
      <Modal.MotionHeader
        animate={partner?.isSubscription}
        onClose={handleClose}
      >
        <FullSummonerProfile summoner={summoner} options={options} />
      </Modal.MotionHeader>
      <div className="p-3">
        <div className="flex mb-1.5 lg:space-x-3 min-w-max">
          <div className="sm:flex-1 lg:w-[226px]">
            <label className="block text-2xs text-gray-400 mb-2">
              <FormattedMessage id="Tier" />
            </label>
            <SummonerTier tier={summoner.tier} />
          </div>
          {summoner.previousTiers.length > 0 && (
            <div className="flex-1">
              <label className="block text-2xs text-gray-400 mb-2">
                <FormattedMessage id="Previous season" />{' '}
                <FormattedMessage id="Tier" />
              </label>
              <SummonerTier tier={summoner.previousTiers[0]} />
            </div>
          )}
        </div>
        <div className="flex lg:space-x-3 sm:flex-col">
          <div className="flex flex-col items-start">
            <label className="block text-2xs text-gray-400 mb-2 mt-3">
              <FormattedMessage id="Primary" />
            </label>
            <div className="flex items-center min-h-[40px]">
              <ChampionIcon
                championId={champion}
                championName={championDataList?.[champion]?.name?.[locale]}
              />
              <div className="ml-2 flex items-center">
                <PositionIcon
                  size={'large'}
                  color={'#fff'}
                  position={position ?? Position.ALL}
                />
                <div className="ml-1 text-sm">
                  <FormattedMessage id={position} />
                </div>
              </div>
            </div>
          </div>

          {(champion2 || position2) && (
            <div className="flex flex-col items-start">
              <label className="block text-2xs text-gray-400 mb-2 mt-3">
                <FormattedMessage id="Primary" />
              </label>
              <div className="flex items-center min-h-[40px] gap-2">
                {champion2 && (
                  <ChampionIcon
                    championId={champion2}
                    championName={championDataList?.[champion2]?.name?.[locale]}
                  />
                )}
                {position2 && (
                  <div className="flex items-center">
                    <PositionIcon
                      size={'large'}
                      color={'#fff'}
                      position={position2 ?? Position.ALL}
                    />
                    <div className="ml-1 text-sm">
                      <FormattedMessage id={position2} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {queueType && (
            <div className="flex flex-col items-start">
              <label className="text-2xs text-gray-400 mb-2 mt-3">
                <FormattedMessage id="Queue Type" />
              </label>
              <div className="bg-transparent w-max flex items-center pl-4 pr-9 py-2 h-10 text-sm rounded-[4px] border border-gray-700">
                <FormattedMessage id={queueType} />
              </div>
            </div>
          )}
        </div>
        <div className="mt-4 flex flex-col items-start">
          <label className="block text-2xs text-gray-400 mb-2 mt-3">
            <FormattedMessage id="Looking for" />
          </label>
          <RequiredPositionSelect readonly positions={requiredPosition || []} />
        </div>
        {(hasChampions || isInviteAvailable) && (
          <div
            className={classNames('mt-4 flex space-x-4', {
              'justify-between': hasChampions,
              'justify-end': !hasChampions,
            })}
          >
            {hasChampions && (
              <div className="shrink-0">
                <label className="block text-2xs text-gray-400 mb-2">
                  <FormattedMessage id="Latest Champions" />
                </label>
                <div>
                  {summoner.champions.map((champion) => (
                    <div
                      key={`most-champion-sub-${partner.id}-${champion.id}`}
                      className="inline-block relative cursor-pointer mr-2 last:mr-0 group"
                    >
                      <div className="flex items-end space-x-1">
                        <ChampionIcon
                          championId={champion.id}
                          championName={
                            championDataList?.[champion.id]?.name[
                              locale as Locale
                            ]
                          }
                        />
                        {champion.wr && (
                          <span
                            className={classNames(
                              'text-3xs',
                              getWinRateClassName(
                                Number(champion.wr.replace(/%/, '')),
                                true
                              )
                            )}
                          >
                            {champion.wr}
                          </span>
                        )}
                      </div>

                      <div className="opacity-0 group-hover:opacity-100 absolute top-10 left-full -translate-x-1/2 -ml-4 whitespace-nowrap px-2.5 py-2 bg-gray-800 border border-gray-600 rounded z-50 pointer-events-none text-2xs text-left transition-opacity">
                        {
                          championDataList?.[champion.id]?.name[
                            locale as Locale
                          ]
                        }
                        <ul className="mt-2.5">
                          <li className="mb-1.5">
                            <span className="inline-block text-gray-400 min-w-[32px] mr-1.5">
                              <FormattedMessage id="Win Rate" />
                            </span>
                            {champion.wr} ({champion.games}{' '}
                            <FormattedMessage id="Games" />)
                          </li>
                          <li className="mb-1.5">
                            <span className="inline-block text-gray-400 min-w-[32px] mr-1.5">
                              <FormattedMessage id="KDA" />
                            </span>
                            {champion.kda} (
                            <strong className="font-normal">
                              {champion.kdaAvg}
                            </strong>
                            )
                          </li>
                          <li>
                            <span className="inline-block text-gray-400 min-w-[32px] mr-1.5">
                              CS
                            </span>
                            {champion.cs}
                          </li>
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {isInviteAvailable && (
              <button
                type="submit"
                className={classNames(
                  'rounded',
                  'flex items-center space-x-1 px-3 my-1',
                  inviteButtonDisabled
                    ? 'bg-gray-700'
                    : 'bg-red-500 hover:bg-red-light'
                )}
                disabled={inviteButtonDisabled}
                onClick={handleInvite}
              >
                <img
                  src="/images/icons/clash-trophy.png"
                  width={24}
                  height={24}
                />
                <span>
                  <FormattedMessage id="Invite to Clash Team" />
                </span>
              </button>
            )}
          </div>
        )}
        {(note || member?.introduce) && (
          <>
            <label className="block text-2xs text-gray-400 mb-2 mt-3">
              <FormattedMessage id="Note" />
            </label>
            <div className="border border-gray-600 bg-gray-700 px-2.5 py-2 rounded text-xs">
              {member?.introduce ?? note}
            </div>
          </>
        )}
        {summoner.gameResults.length > 0 && (
          <div className="mt-4 mb-2">
            <label className="block text-2xs text-gray-400 mb-2 mt-3">
              <FormattedMessage id="Recent match results" />
            </label>
            <div className="text-2xs mb-1">
              {
                summoner.gameResults.filter((x) => ['WIN', 'MVP'].includes(x))
                  .length
              }
              <FormattedMessage id="W" />{' '}
              {
                summoner.gameResults.filter((x) => ['LOSE', 'ACE'].includes(x))
                  .length
              }
              <FormattedMessage id="L" /> {'('}
              {summoner.gameResults.length} <FormattedMessage id="Games" />
              {')'}
            </div>
            <div className="flex space-x-px">
              {summoner.gameResults.map((result, index) => (
                <span
                  key={`game-result-${index}`}
                  className="flex-1 flex flex-col items-center text-gray-900 text-center text-3xs space-y-1 max-w-[20px]"
                >
                  <span
                    className={classNames(
                      'text-white text-center text-3xs py-0.5 w-full h-full',
                      {
                        'bg-gray-600': ['DRAW', 'UNKNOWN'].includes(result),
                        'bg-green-500': ['WIN', 'MVP'].includes(result),
                        'bg-red-500': ['LOSE', 'ACE'].includes(result),
                        'rounded-l-sm': index === 0,
                        'rounded-r-sm':
                          index === summoner.gameResults.length - 1,
                      }
                    )}
                  >
                    {['WIN', 'MVP'].includes(result) && (
                      <FormattedMessage id="W" />
                    )}
                    {['LOSE', 'ACE'].includes(result) && (
                      <FormattedMessage id="L" />
                    )}
                    {['DRAW', 'UNKNOWN'].includes(result) && (
                      <FormattedMessage id="D" defaultMessage={''} />
                    )}
                  </span>

                  <span
                    className={classNames('w-1 h-1 rounded-full inline-block', {
                      'bg-yellow-400': result === 'MVP',
                      'bg-purple-400': result === 'ACE',
                    })}
                  />
                </span>
              ))}
            </div>
          </div>
        )}
      </div>

      {alertVisible && (
        <AlertPortal>
          <Alert
            message={message}
            errorCode={errorCode}
            hide={hideAlert}
            className="bottom-14"
          />
        </AlertPortal>
      )}
    </Modal>
  );
};

export default QuickPlayPartnerDetailModal;
