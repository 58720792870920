import classNames from 'classnames';
import React from 'react';
import TableHeaderCell from '../../TableHeaderCell';

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
};

const QuickPlayPartnerListHeader: React.FC<Props> = ({ className }) => {
  return (
    <div className={classNames(className, 'flex bg-gray-850 rounded-t')}>
      <TableHeaderCell id="Summoner Name" className="w-44 rounded-tl" />
      <TableHeaderCell id="Primary" className="w-16" />
      <TableHeaderCell id="Secondary" className="w-16" />
      <TableHeaderCell id="Tier" className="w-[46px]" />
      <TableHeaderCell id="Looking for" className="w-[66px]" />
      <TableHeaderCell id="Latest Champions" className="w-36" />
      <TableHeaderCell id="Win Rate" className="w-[127px]" />
      <TableHeaderCell id="KDA" className="w-[85px]" />
      <TableHeaderCell id="Note" className="flex-1" />
      <TableHeaderCell id="Registered" className="w-[70px]" />
      <div className="w-8" />
    </div>
  );
};

export default QuickPlayPartnerListHeader;
